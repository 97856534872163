<template>
  <article class="HistoryItem" v-bind:class="{new : isNew}">
    <Tile class="event">
      <div class="profileLine">
        <ProfilePic :icon="$store.state.game.game_obj.players[item.playerA].avatar"></ProfilePic>
      </div>
      <div class="time font-style--readable">
        {{hours()}}:{{minutes()}}:{{seconds()}}        
      </div>
      <div class="content">
        <div class="content--primary">
          <font-awesome-icon v-if="item.type === 'life'" class="counter--icon" :icon="['fas', 'heart']" />
          <font-awesome-icon v-if="item.type === 'roll_random'" class="counter--icon" :icon="['far', getIconClass(item)]" />
          <font-awesome-icon v-if="item.type === 'commander_damage'" class="counter--icon" :icon="['fas', 'swords']" />
          <font-awesome-icon v-if="item.type === 'counter'  && getIconClass(item) === 'helmet-battle'" class="counter counter--icon" :icon="['far', getIconClass(item)]" />
          <i v-if="item.type === 'counter' && getIconClass(item) !== 'helmet-battle'" class="fa counter counter--icon" :class="getIconClass(item)"></i>
          <div class="details">
            <ProfilePic v-if="item.type === 'commander_damage'" size="small" :icon="$store.state.game.game_obj.players[item.playerB].avatar"></ProfilePic>
            <span class="font-style--readable">{{getOldValue(item)}}</span>
            <span v-if="item.type !== 'roll_random'" class="arrow font-style--readable"><font-awesome-icon class="arrow" :icon="['far', 'long-arrow-alt-right']"/></span>
            <span v-if="item.type !== 'roll_random'" class="font-style--readable">{{item.newValue}}</span>
            <span v-if="item.type !== 'roll_random'" class="delta">
              <span class="valChange font-style--readable">
                ({{item.value >= 0 ? '&plus;' : '&minus;'}}{{Math.abs(item.value)}})
              </span>
            </span>
          </div>
        </div>
        <div class="content--secondary">
          <!-- Full text for life change -->
          <span v-if="item.type === 'life'" class="font-style--readable"> {{$store.state.game.game_obj.players[item.playerA].name}}'s life {{item.value > 0 ? 'rose' : 'fell'}} from {{item.oldValue}} to {{item.newValue}}</span>

          <!-- Full text for random roll -->
          <span v-if="item.type === 'roll_random'" class="font-style--readable"> {{$store.state.game.game_obj.players[item.playerA].name}}
            {{ item.extraInfo === 'd2' ? 'flipped a coin and got' : '' }}
            {{ item.extraInfo === 'd6' ? 'rolled a d6 and got a' : '' }}
            {{ item.extraInfo === 'd20' ? 'rolled a d20 and got a' : '' }}
            {{ item.extraInfo === 'players' ? 'chose a random player and picked' : '' }}

            {{ getOldValue(item)}}
          </span>

          <!-- Full text for counter change -->
          <span v-else-if="item.type === 'counter'">
            <span v-if="item.extraInfo === 'Commander_Tax_0' || item.extraInfo === 'Commander_Tax_1'" class="font-style--readable">
              {{$store.state.game.game_obj.players[item.playerA].name}}'s commander tax <span v-if="commanderTaxName() !== 'Commander'">for</span> <i v-if="commanderTaxName() && commanderTaxName() !== 'Commander'" class="font-style--readable">{{commanderTaxName()}}</i> {{item.value > 0 ? 'rose' : 'fell'}} from {{item.oldValue}} to {{item.newValue}}
            </span>
            <span v-else class="font-style--readable">
              {{$store.state.game.game_obj.players[item.playerA].name}}'s <i class="font-style--readable">{{item.extraInfo}}</i> counter {{item.value > 0 ? 'rose' : 'fell'}} from {{item.oldValue}} to {{item.newValue}}
            </span>
          </span>

          <!-- Full text for cmdr damage -->
          <span v-else-if="item.type === 'commander_damage'" class="font-style--readable">
            {{$store.state.game.game_obj.players[item.playerA].name}}'s commander <i v-if="commander() && commander().name !== 'Commander'" class="font-style--readable">{{commander().name ? ' '+commander().name+'' : ''}}</i> {{item.value > 0 ? 'dealt' : 'removed'}} {{Math.abs(item.value)}} damage {{item.value > 0 ? 'to' : 'from'}} {{$store.state.game.game_obj.players[item.playerB].name}}
          </span>
        </div>
      </div>
    </Tile>
  </article>
</template>

<script>
import Tile from '@/components/Tile.vue';
import ProfilePic from '@/components/ProfilePic.vue';
export default {
  name: 'HistoryItem',
  props: ['item', 'isNew'],
    components: {
      Tile,
      ProfilePic,
  },
  data: () => {
    return {
      hours: function() {
        const x = new Date(this.item.time).getHours();
        return ("0" + x).slice(-2);
      },
      minutes: function() {
        const x = new Date(this.item.time).getMinutes();
        return ("0" + x).slice(-2);
      },
      seconds: function() {
        const x = new Date(this.item.time).getSeconds();
        return ("0" + x).slice(-2);
      },
      commander: function() {
        return this.$store.state.game.game_obj.players[this.item.playerA].commanderList[this.item.extraInfo]
      },
      commanderTaxName: function() {
        return this.$store.state.game.game_obj.players[this.item.playerA].commanderList[this.item.extraInfo.split('_')[2]].name;
      }
    }
  },
  methods: {
    getOldValue: function(item) {
      if(this.getIconClass(item) === 'users') {
        console.log(item);
        return this.$store.state.game.game_obj.players[item.oldValue].name;
      } else {
        return item.oldValue;
      }
    },
    getIconClass: function(item) {
      return item.extraInfo2;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.HistoryItem {
  transition: transform .5s linear, max-height .5s linear;
  height: auto;
  max-height: 100px;

  &.new {
    max-height: 0px;
    overflow: none;
    transform: translate(0, 200px);
  }

  @text-opacity: .35;

  .profileLine {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-self: flex-start;
  }

  .event {
    display: flex;
    align-items: center;
    color: @font-color-primary;
    

    background: @background-color-secondary;
    position: relative;

    .time {
      position: absolute;

      right: 8px;
      top: 5px;
      font-size: .75em;
      opacity: @text-opacity;
    }

    .delta {
      font-size: .5em;
      opacity: @text-opacity;
      display: flex;
      height: 30px;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }

    .content {
      margin-left: 10px;

      &--primary {
        font-size: 1.5em;
        display: flex;
        flex-flow: row;
        align-items: center;
        vertical-align: center;
        margin-bottom: 0px;

        .details {
          display: flex;
          flex-flow: row;
          margin-left: 3px;
        }

        .counter--icon {
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .arrow {
          margin-left:2px;
        }

        .counter {
          font-size: .75em;
          color: @background-color-secondary;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          padding: 5px;
          box-sizing: border-box;
          margin-right: 3px;
        }

        .ProfilePic {
          height: 100%;
          margin: 0 5px 3px 5px;
        }
      }

      &--secondary {
        line-height: 14px;
        & > * {
          font-size: .75em;
          opacity: @text-opacity;

        }
        
      }
    }
  }
}
</style>
