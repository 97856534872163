<template>
  <div class="history" v-bind:class="{empty: events.length === 0}">
    <section class="historyBox" v-if="events.length > 0">
      <div id="placeholder"></div>
      <HistoryItem
        v-for="historyItem in events"
        :key="historyItem.id"
        :isNew="$store.state.player.newEventList.find(x => x.id === historyItem.id)"
        :item="historyItem">
      </HistoryItem>
    
    </section>

    <div class="emptyPage" v-if="events.length === 0">
      <div class="empty--content">
        <font-awesome-icon :icon="['far', 'scroll-old']" />
        <div class="text">Game history will be tracked here once something happens</div>
      </div>
    </div>
  </div>
</template>
<script>
  import HistoryItem from "@/components/HistoryItem.vue";

  export default {
    name: 'History',
    components: {
      HistoryItem
    },
    mounted() {
      this.isScrollStuckToBottom = true;
      this.stickToBottom();
    },
    computed: {
      events: function() {
        return this.$store.state.game.game_obj.history.slice().reverse();
      }
    },
    watch: {
      events: function() {
        const main = document.getElementById('main');
        if(Math.ceil(main.scrollHeight - Math.abs(main.scrollTop)) === main.clientHeight) {
          this.isScrollStuckToBottom = true;
        } else {
          //alert(main.scrollHeight - Math.abs(main.scrollTop) + ' and ' + main.clientHeight);
          this.isScrollStuckToBottom = false;
        }
      }
    },
    updated: function () {
      this.$nextTick(function () {
        this.stickToBottom();

      })
    },
    data: () => {
      return {
        isScrollStuckToBottom: true
      }
    },
    methods: {
      stickToBottom: function() {
        if(this.isScrollStuckToBottom) {
          // The 9's are kinda hacky but using scrollheight doesn't work on mobile.
          const main = document.getElementById('main');
          main.scrollTop = 999999;
        }
      }
    },

  }
</script>
<style scoped lang="less">
.history {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  &.empty {
    justify-content: center;
  }
}

#main {
  scroll-behavior: auto !important;
}

#placeholder {
  display: block;
  height: 10px;
}

.historyBox {
  display: flex;
  flex-direction: column-reverse;
  align-items: left;
  text-align: left;
  width: 100%;
  overflow: hidden;

  // This is a hack, but it works relatively well at aligning items
  // coming in from the bottom.
  min-height: 80vh;
}

</style>